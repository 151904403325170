
.title {
    font-size: 26px;
    margin-bottom: 60px;
    margin-top: 40px;
}

.facts {
    background-color: #f5f5f5;
    padding-bottom: 80px;
}

.factsWrap {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0px auto 10px;
    text-align: center;
}

.graph {
    width: 180px;
    height: 180px;
    margin: 0 auto;
    margin-bottom: 0px;
    position: relative;
    text-align: center;
    margin-bottom: 10px;
}

.graph .graphLeftSide {
    left: 0;
}

.graph .graphLeftSide, .graph .graphRightSide {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    overflow: hidden;
}

.graph .graphLeftContainer, .graph .graphLeftContainer {
    -webkit-transform: rotate(25deg);
    -moz-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
}

.graph .graphRightContainer, .graph .graphRightContainer {
    -webkit-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    -ms-transform: rotate(-25deg);
    transform: rotate(-25deg);
}

.graph .graphLeftContainer {
    left: 0;
}

.graph .graphLeftContainer, .graph .graphRightContainer {
    position: absolute;
    width: 200%;
    height: 100%;
}

.graph .graphLeftHalf {
    left: 0;
}

.graph .graphLeftHalf, .graph .graphRightHalf {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    overflow: hidden;
}

.graph .graphLeftHalf::after {
    left: 0;
}

.graph .graphLeftHalf::after, .graph .graphRightHalf::after {
    position: absolute;
    top: 0;
    height: 100%;
    width: 200%;
    border: 5px #212d3a solid;
    border-radius: 50%;
    content: '';
}

.graph .graphRightHalf::after {

    right: 0;

}

.graph .graphRightHalf {
    right: 0;
}

.graph .graphRightContainer {
    right: 0;
}

.graph .graphRightSide {
    right: 0;
}

.graph i {
    font-size: 40px;
    line-height: 180px;
}

.factsWrapNum {
    color: #212d3a;
    font-size: 35px;
    line-height: 60px;
    width: 100%;
    margin: 0 auto;
    margin-top: 0px;
    text-align: center;
    margin-top: -40px;
}

.factsWrap h6 {
    font-weight: 700;
    padding-top: 15px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-size: 20px;
    line-height: 18px;
    color: #212d3a !important;
    margin-top: 15px;
}

@media screen and (max-width: 600px) {
    .facts {
        display: none
    }
}
.cont{
    margin-top: 30px;
    min-height: 550px;

}
.ArticleSidebar{
    padding: 25px;
    background-color: #f5f5f5;
}
.ArticleSidebar h4{
    font-size: 24px;
    color: #000;
    line-height: 20px;
    margin-bottom: 40px;
}
.ArticleContent{

}
.title h1{
    font-size: 24px;
    color: #000;
    line-height: 32px;
    margin-bottom: 40px;
}

.nav ul li {

    display: inline-block;
    width: 100%;
    border-top: 1px solid lightgray;
    padding: 10px;
}
.nav ul li a{
    color: #212d3a;
}

.nav ul li:first-child{
    border-top: 0px;
}
.nav ul li.current a{
    color: #b92034;
}
.type{
    font-size: 15px;
    color: gray;
}
.preload{
    position: fixed;
    top:50%;
    left: 50%;
}
.date{

}
.downloadBtn{
    width: 100%;
}
.rowContainer{

}
@media only screen and (max-width: 576px)  {
    .downloadBtn{
        bottom: -45px;
    }
}
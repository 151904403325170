.App {

}
.App a:hover{
  text-decoration: none;
}
.App img{
  max-width: 100%;
}
li {
  list-style-type: none; /* Убираем маркеры */
}
ul {
  margin-left: 0; /* Отступ слева в браузере IE и Opera */
  padding-left: 0; /* Отступ слева в браузере Firefox, Safari, Chrome */
}
.red{
  color: #b92034;
}
.body-section{
  padding-bottom: 20px;
}
.breadcrumdBottom{
  background: #212d3a;
  min-height: 75px;
}
.breadcrumdItem:hover{
  color: #b92034;
}
.breadcrumdCont{
 width: 100%;
  padding-top: 22px;
}
.breadcrumbItem{
  display: inline-block;
  color: lightgray;
  margin-left: 20px;
  padding-bottom: 5px;
}
.breadcrumbItem::before{
  content: '\02C3';
  padding-right: 10px;
}
.breadcrumbTitle{
  margin-left: 11px;
  font-weight: 600;
  font-family: inherit;
  font-style: inherit;
  vertical-align: baseline;
  line-height: 24px;
  font-size: 24px;
  color: #fff;
  display: inline-block;
}
.App .breadcrumdCont a:hover .breadcrumbItem{
  color: #b92034;
}
.App a{
  color: #b92034;
}
.App a:hover{
  color: #212d3a;
}
.markdown p{
  margin-bottom: 0.5rem;
}

.markdown ul, ol{
    list-style: disc outside none;
    display: block;
    padding: 0 0 0 40px;
}
.markdown ol { 
  list-style-type: decimal;
}
.markdown li {
  list-style-type: inherit;
  display: list-item;
}

.markdown ul ul, .markdown ol ul {
  list-style-type: circle;
  margin-left: 15px; 
}
.markdown ol ol, .markdown ul ol { 
  list-style-type: lower-latin;
  margin-left: 15px; 
}
.search{
    background: #212d3a;
    min-height: 280px;
    padding-bottom: 40px;
    padding-top: 30px;
}
.searchRow{
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    padding-top: 20px;
}
.library{
    margin-bottom: -10px;
    display: inline-block;
}
.image{
    width: 60px;
    padding-bottom: 13px;
}
.button{
    color: #fff;
    user-select: none;
    width: 70px;
    float: right;

}
.button button{
    color: #fff;
    cursor: pointer;

    display: block;
    text-align: center;
    border: none;
    width: 70px;
    height: 40px;
    padding: 0;

    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    align-items: flex-start;
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial;
    border-image: initial;
    background: #b92034;
    border-radius: 0 5px  5px 0;
}
.form{
    color: #fff;
}
.area{
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    vertical-align: baseline;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    max-width: 800px;
    width: 100%;
}

.input{
    width: 100%;
    padding: 0 70px 0 0;
}
.input input{
    outline:none;
    display: block;
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
    color: #000;
    line-height: 16px;
    border: none;
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 4px 0 0 4px;

}
a.extSearch{
    color: lightgray;
    text-decoration: underline;

}
a.extSearch:hover{
    color: #fff;
    animation: 1s ease infinite pulse;
    text-decoration: underline;
}
.searchTitle{
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-bottom: 25px;
}
.root {
  margin-bottom: 32px;
}

.root a {
  display: inline-block;
  margin-bottom: 8px;
  padding: 8px 16px;
  background-color: var(--clr-secondary);
  color: #fff !important;
  border-radius: 4px;
  transition: opacity 100ms linear;
}

.root a:hover {
  opacity: .85;
}
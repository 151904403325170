.cont {
    padding-bottom: 32px;
}
.title{

    font-size: 26px;
    margin-bottom: 30px;
    margin-top: 40px;
}
.titleMin{
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 40px;
    color: #212d3a;
}
ul.keywords li .name{
    text-decoration: underline;
    color: #212d3a;
}
ul.keywords li .count{

    color: #b92034;
    border: 2px solid #b92034;
    border-radius: 4px;
    padding: 0px 4px;
}
ul.keywords li{
    line-height: 1.8;
}
@media screen and (max-width: 575px){
    .titleMin{
        margin-top: 0px;
    }
}
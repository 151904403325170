
.paginator {
    margin: 10px;
    margin-left: 0;
}
.pageNumber {
    cursor: pointer;
    padding: 2px 6px;
    border: 2px solid #212d3a;
    margin-right:5px;
}

.pageNumber.selectedPage {
    font-weight: bold;
    color: #b92034;
    border-color: #b92034;;
}
.paginator button{
    background-color: #fff;
    width: auto;
    margin-right: 5px;
}
.title h1{
    font-size: 24px;
    color: #000;
    line-height: 20px;
    margin-bottom: 40px;
}
.cont{
    padding: 25px;
    background-color: #f5f5f5;
}
.nav ul li{
    display: inline-block;
    width: 100%;
    border-top: 1px solid lightgray;
    padding: 5px;
}
.nav ul li p{
    color: #212d3a;
    font-weight: 300;
    font-size: 16px;
}
.nav ul li:first-child{
    border-top: 0px;
}
.nav ul li.current p{
    color: #b92034;
}
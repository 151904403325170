.contInst a{
    color: #b92034;
}
.contInst{
    min-height: 570px;
}
.contDepart{
    min-height: 600px;
}
.Authcont{
    min-height: 600px;
}
.DepItem a{
    color: #fff;
}
.card{
    background-color: #212d3a;
}
.DepItem:hover .card{
    background-color: #b92034;

}
.DepItem:hover a{
    color: #fff;
}
.Edutitle{
    text-decoration: none;
    font-size: 20px;
    color: #fff;
}
.Edutitle:hover{
    color: lightgray;
}
.artItem{
    margin-bottom: 30px;
    padding-bottom: 2+0px;
    border-bottom: 3px dotted lightgray;
}
.artItem img{
    width: 100%;
}
.title h1{
    font-size: 24px;
    color: #000;
    line-height: 20px;
    margin-bottom: 40px;
}
.educont{
    padding: 25px;
    background-color: #f5f5f5;
}
.edunav ul li{
    display: inline-block;
    width: 100%;
    border-top: 1px solid lightgray;
    padding: 5px;
}
.edunav ul li p{
    color: #212d3a;
    font-weight: 300;
    font-size: 16px;
}
.edunav ul li:first-child{
    border-top: 0px;
}
.edunav ul li.current p{
    color: #b92034;
}
.logoInstitute{
    margin-right: 20px;
    border-radius: 30px;
    width: 60px;
}
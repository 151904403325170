.logo{
    width: 100px;
}
.headerTop{
    background: #b92034;
}
.siteName{
    font-size: 38px;
    text-align: center;

    color: #fff;

}


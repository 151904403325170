.cont{

    width: 100%;
    background: #212d3a;
    padding-top: 20px;
    padding-bottom: 20px;
}
.footerTitle{
    color: #fff;
    font-size: 28px;
    font-weight: 600;
}
.footerSubtitle{
    color: lightgray;
}
.kart{
    width: 100%;
}
.desc{

    color: #9f9f9f;
}
.fotImg{
    width: 90px;
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
}
.form input{
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;

}
.form textarea{
    width: 100%;
    margin-bottom: 10px;
}
.form select{
    width: 100%;
    margin-bottom: 10px;

}
button{
    width: 100%;
}
.form input[type="file"] {
    border: 0px;
}
.form input[type="checkbox"] {
    width: auto;
}
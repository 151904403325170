.artItem{
    margin-bottom: 30px;
    padding-bottom: 2+0px;
    border-bottom: 3px dotted lightgray;
}
.artItem img{
    width: 100%;
}
.title{
    text-decoration: underline;
    font-size: 20px;

}
.author{
    font-size: 16px;
    color: gray;
}
.authorname a{
    color: #212d3a;
}
.authorname a:hover{
    color: #b92034;
}
.date{
    font-size: 14px;

}
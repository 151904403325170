.login input{
    width: 100%;
}

.login{
    margin-top: 150px;
    margin-bottom: 150px;
    max-width: 500px;
    min-height: 420px;

    text-align: center;
}
.admin button{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 500px;
}
.admin{
    min-height: 550px;

}
.section {
    padding-top: 20px;
}
.section li{
    display: block;
}
.title{
    width: 100%;
    font-size: 20px;
    color: #000;
    line-height: 24px;
    font-weight: inherit;
    font-style: inherit;
}
.title sup{
    background: #b92034;
    border-radius: 5px;
    padding: 0px 3px;
    color: #fff;
}
.section ul li {
    font-style: italic ;
    color: #b92034;
    font-size: 16px;

}
.section ul li:hover{
    color: #212d3a;

}
ul{
    width: 100%;
}